:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f4f4f4;
  --text-color: #333;
  --card-background: #fff;
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-header {
  background-color: var(--primary-color);
  padding: 1rem;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-header h1 {
  margin: 0;
  font-size: 1.5rem;
}

main {
  flex: 1;
  padding: 2rem;
}

.dark-mode {
  --primary-color: #2980b9;
  --secondary-color: #27ae60;
  --background-color: #2c3e50;
  --text-color: #ecf0f1;
  --card-background: #34495e;
  --card-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.dark-mode body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.electric-car-list {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filters input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #4CAF50;
  color: white;
}

tr:hover {
  background-color: #f5f5f5;
}

.add-car-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.add-car-form h2 {
  margin-bottom: 20px;
}

.add-car-form input,
.add-car-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-car-form button {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-car-form button:hover {
  background-color: #45a049;
}

.dark-mode-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
}