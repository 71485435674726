:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #f4f4f4;
  --text-color: #333;
  --card-background: #fff;
  --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dark-mode {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --background-color: #2c3e50;
  --text-color: #ecf0f1;
  --card-background: #34495e;
  --card-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.electric-car-list {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
}

.electric-car-list header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.electric-car-list h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
}

.dark-mode-toggle {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.filters {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
  max-width: 300px;
}

.filter-category {
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.filter-category label {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 5px;
}

.filter-category label input[type="checkbox"] {
  margin-right: 10px;
}

.filter-inputs {
  display: flex;
  gap: 10px;
}

.filter-inputs input,
.filter-inputs select {
  flex: 1;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  background-color: var(--card-background);
  color: var(--text-color);
}

.button-group {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.apply-button,
.reset-button {
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
}

.apply-button {
  background-color: var(--secondary-color);
}

.apply-button:hover {
  background-color: #27ae60;
}

.reset-button {
  background-color: #e74c3c;
}

.reset-button:hover {
  background-color: #c0392b;
}

.apply-button:disabled,
.reset-button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.car-count {
  font-style: italic;
  margin-bottom: 20px;
  font-size: 1.3rem;
}

.car-list {
  list-style-type: none;
  padding: 0;
}

.car-item {
  background-color: var(--card-background);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 15px;
  box-shadow: var(--card-shadow);
  transition: transform 0.3s;
}

.car-item:hover {
  transform: translateY(-3px);
}

.car-item h2 {
  margin-top: 0;
  color: var(--primary-color);
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.car-specs {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.car-spec {
  flex: 1;
  min-width: 150px;
}

.car-spec span {
  font-weight: bold;
  color: var(--secondary-color);
}

.car-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.car-table th,
.car-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid var(--text-color);
}

.car-table th {
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  user-select: none;
}

.car-table th:hover {
  background-color: var(--secondary-color);
}

.car-table tbody tr {
  background-color: var(--card-background);
}

.car-table tbody tr:hover {
  background-color: var(--secondary-color);
  color: white;
}

.more-filters-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
}

.more-filters-button:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }

  .filters input,
  .filters select,
  .button-group {
    width: 100%;
  }

  .car-specs {
    flex-direction: column;
  }

  .car-table {
    font-size: 14px;
  }

  .car-table th,
  .car-table td {
    padding: 8px;
  }
}